import { inject } from '@angular/core'
import { ResolveFn, ActivatedRouteSnapshot } from '@angular/router'
import { I18nService, LocaleShort } from 'app/_services/i18n.service'

export const i18nResolver: ResolveFn<void> = (route: ActivatedRouteSnapshot) => {
    const i18nService = inject(I18nService)
    const languageId: LocaleShort = route.params['language']
    if (languageId) {
        const localeNew = i18nService.idLocaleMap[languageId]
        i18nService.locale = localeNew
    }
    return i18nService.onReady(() => i18nService)
}
